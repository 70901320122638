var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"padding":"10px"}},[_c('Row',{attrs:{"gutter":10}},[_c('Col',{attrs:{"span":"4"}},[_c('Input',{attrs:{"placeholder":"名称","clearable":""},model:{value:(_vm.searchKey.name),callback:function ($$v) {_vm.$set(_vm.searchKey, "name", $$v)},expression:"searchKey.name"}})],1),_c('Col',{attrs:{"span":"2"}},[_c('Button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.search()}}},[_vm._v("查询")])],1),_c('Col',{staticStyle:{"text-align":"right"},attrs:{"span":"18"}},[_c('Button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.add()}}},[_vm._v("新增")])],1)],1),_c('Row',{staticStyle:{"margin-top":"15px"}},[_c('Col',{attrs:{"span":"24"}},[_c('Table',{attrs:{"stripe":"","border":"","columns":_vm.columns,"data":_vm.list},scopedSlots:_vm._u([{key:"no",fn:function(ref){
var index = ref.index;
return [_vm._v(_vm._s(index + 1))]}},{key:"hidden",fn:function(ref){
var row = ref.row;
return [_c('Checkbox',{attrs:{"true-value":1,"false-value":0},on:{"on-change":function($event){return _vm.onHiddenChange(row.paper_id, $event)}},model:{value:(row.paper_hidden),callback:function ($$v) {_vm.$set(row, "paper_hidden", $$v)},expression:"row.paper_hidden"}})]}},{key:"state",fn:function(ref){
var row = ref.row;
return [(row.paper_state === 0)?_c('span',[_vm._v("待审核")]):_vm._e(),(row.paper_state === 10)?_c('span',[_vm._v("已通过")]):_vm._e()]}},{key:"action",fn:function(ref){
var row = ref.row;
return [_c('Button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.mod(row.paper_id)}}},[_vm._v("修改")]),_c('Button',{staticStyle:{"margin-left":"5px"},attrs:{"type":"primary","disabled":row.paper_count<1},on:{"click":function($event){return _vm.look(row.paper_id)}}},[_vm._v("查看提交")]),_c('Button',{staticStyle:{"margin-left":"5px"},attrs:{"type":"error"},on:{"click":function($event){return _vm.del(row.paper_id)}}},[_vm._v("删除")])]}}])})],1)],1),_c('Row',{staticStyle:{"margin-top":"15px"}},[_c('Col',{staticStyle:{"text-align":"center"},attrs:{"span":"24"}},[_c('Page',{attrs:{"show-sizer":"","show-elevator":"","show-total":"","total":_vm.total,"current":_vm.searchKey.page,"page-size":_vm.searchKey.pagesize},on:{"on-change":_vm.pageChange,"on-page-size-change":_vm.pageSizeChange}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }